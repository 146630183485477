
import {phraseDeclension} from '~/plugins/formatting';

export default {
  name: 'Countdown',
  props: {
    to: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    strokeColor: {
      type: String,
      default: '#000000',
    },
    underneathStrokeColor: {
      type: String,
      default: '#696969',
    },
    fillColor: {
      type: String,
      default: '#ffffff',
    },
    borderColor: {
      type: String,
      default: '#000',
    },
    maxWidth: {
      type: String,
      default: '73rem',
    },
    background: {
      type: String,
      default: 'transparent',
    },
    breakpoints: {
      type: Object,
      required: true,
    },
  },
  data() {
    const now = new Date();
    const target = new Date(this.to);

    return {
      end: target < now,
      diff: target - now, // разница
      target,
      updateTask: null,
    };
  },
  computed: {
    left() {
      const s = Math.round(this.diff / 1000);
      const r = {};

      r.days = Math.floor(s / 86400);
      r.hours = Math.floor((s - r.days * 86400) / 3600);
      r.minutes = Math.floor((s - r.days * 86400 - r.hours * 3600) / 60);
      r.seconds = s - r.days * 86400 - r.hours * 3600 - r.minutes * 60;

      return r;
    },
    getDayLabel() {
      return phraseDeclension(this.left.days, ['день', 'дней', 'дня'], false, false)
    },
    getHourLabel() {
      return phraseDeclension(this.left.hours, ['час', 'часов', 'часа'], false, false)
    },
    getMinLabel() {
      return phraseDeclension(this.left.minutes, ['минута', 'минут', 'минуты'], false, false)
    },
    getSecLabel() {
      return phraseDeclension(this.left.seconds, ['секунда', 'секунд', 'секунды'], false, false)
    },
    circles() {
      return [
        {
          id: '4',
          steps: 365,
          value: this.left.days,
          stepLength: -1,
          label: this.getDayLabel,
        },
        {
          id: '3',
          steps: 24,
          value: this.left.hours,
          stepLength: -1,
          label: this.getHourLabel,
          dependentCircles: [ '4' ],
        },
        {
          id: '2',
          steps: 60,
          value: this.left.minutes,
          stepLength: -1,
          label: this.getMinLabel,
          dependentCircles: [ '3' ],
        },
        {
          id: '1',
          steps: 60,
          value: this.left.seconds,
          stepLength: -1,
          label: this.getSecLabel,
          dependentCircles: [ '2' ],
        },
      ]
    },
    countStyles() {
      return {
        '--_count-width': this.maxWidth,
        '--_count-background': this.background,
        '--_count-padding': this.options.padding,
        '--_count-border-color': this.borderColor,
        '--_count-border-width': `${this.options.borderWidth}px`,
        '--_count-border-radius': `${this.options.borderRadius}px`,
        '--_count-underneath-stroke-width': `${this.options.underneathStrokeWidth}px`,
        '--_count-label-font-size': `${this.options.labelFontSize}px`,
        '--_count-label-bottom': `${this.options.labelBottom}px`,
      }
    },
    options() {
      switch (this.$mq) {
        case 'mobileXs':
          return this.breakpoints.mobile
        case 'tablet':
        case 'mobile':
          return this.breakpoints.tablet
        default:
          return this.breakpoints.pc
      }
    },
  },
  watch: {
    end(value) {
      if (value) {
        this.$emit('onEnd')
      }
    },
  },
  mounted() {
    this.update();
  },
  beforeDestroy() {
    this.updateTask && clearTimeout(this.updateTask)
  },
  methods: {
    update() {
      const now = new Date(new Date().toUTCString());

      if (this.target > now) {
        this.diff = this.target - now;
        this.updateTask = setTimeout(this.update, 1000);
      } else {
        this.end = true;
      }
    },
  },

}
